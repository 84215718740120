import React, { useRef } from "react";
import axios from "axios";
import { format } from "date-fns";
import { useOutletContext,useNavigate  } from "react-router-dom";
import { landingPageLabels } from "../../utils/labels";
import {isValidCheckpointDate} from "../../utils/utils";
import { Link } from "react-router-dom";
import { Notification } from "@cambridgeassessment/cambridge-ui";
import "./welcomMessageScreen.css";
import IaCheckpointNotification from "../../containers/Checkpoints/InternalAdminCheckpoint/IaCheckpointNotification";
import { useSelector ,useDispatch} from "react-redux";
import EoCheckpointModal from "../../containers/Checkpoints/EoCheckpointModal";
import TeacherCheckPoint from '../../containers/Checkpoints/TeacherModal'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {useState,useEffect} from "react";
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  muleApiInstance,
  mulePostApiInstance,
  APP_ERROR_CODES,
} from "../../utils/constants";
import timezone from "dayjs/plugin/timezone";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableContainer,
  Paper,
  Dialog,
  TableHead,
  Box,
  useTheme
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import { setMainFilter,setTableData, setApprove,setEoApprove, setTabValue, setIneligibleViewActive, setDetailViewActive,setSummaryViewActive, setAlertClick }from "../../redux/actions/actions";
import Checkbox from '@mui/material/Checkbox';
import { usePickerState } from "@mui/x-date-pickers/internals";
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

dayjs.extend(utc);
dayjs.extend(timezone);

const WelcomeMessageScreen = () => {

  const useStyles =  makeStyles({
    checkboxCell: {
      display: 'flex',
      alignItems: 'center',
      
    },
    checkbox:{
      '&.Mui-checked':{
        '&:hover' :{
        backgroundColor: '#0076BD',
      },
       '&:focus':{
        backgroundColor: '#0076BD',
       },
      },
    },
  })
  
  const outletProps = useOutletContext();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const[tab,setTab] = useState([]);
  const [Eoflag,setEoFlag] = useState(true);
  const [selectedCheck,setSelectedCheck] = useState([]);
  const [checkPointStatus,setCheckPointStatus] = useState();
  const[open,setOpen] = useState(true);
  const state = useSelector((fnState) => fnState);
  const { welcomePageProps } = outletProps;
  const notificationRef = useRef();
  const alertRef = useRef();
  const notificationTeacherRef = useRef();
  const notificationEORef = useRef();
  const [selectedRows,setSelectedRows] = useState([]);
  const [point,setPoint] = useState([])
  const [pointFlag,setPointFlag] = useState(false);
  const centerNumber = state.selectedOrg.orgId;
  const currentYr = 'Summer'+' ' + new Date().getFullYear();
  const [initialData,setInitialData] = useState([]);
 const [check,setCheck] = useState([]);
 const [manualSelection,setManualSelection] = useState([]);
 const [user,setUser] = useState();
 const [tstamp, setTstamp] = useState();
 const [checkedData,setCheckedData] = useState([]);
 const [overrideData,setOverrideData] = useState({});
 const [ineligibleDetails,setIneligibleDetails] = useState({});
 const [ineligibleData,setIneligibleData] = useState({});
 const navigate = useNavigate();
 const [subject,setSubject] = useState([]);
 const [err,setErr] = useState();
 const [dataCheck,setDataCheck] = useState()
 const currentyr =  new Date().getFullYear();
 const MonthYrCalc = () =>
  {
    const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
    const currentYr =  new Date().getFullYear();
    const currMonth = new Date().getMonth();
    const result =  currMonth <= 8 ? `${currentYr}` : `${currentYr + 1}`
    return result;
  }  

  const getSubjectStatus = () => {
    return muleApiInstance({
      url: "subjectLevelAndQualification",
      params: {
        centreId: state.selectedOrg.orgId,
      },
    })
    .then((res) =>
  {
    setSubject(res.data)
  })
   .catch((err) =>
  {
    console.log(err);
  })
  };

  

useEffect(() =>{
  const getCheckpointStatus = () => {
    return muleApiInstance({
      url: "checkPointStatusForCentres",
      params: {
        centreId: state.selectedOrg.orgId,
      },
    })
    .then((res) =>
        {   
        setDataCheck(res.data)
        console.log(res.data,"responseData")
        console.log(res.data[0].subjectAndLevel.value,"value");
        const datacheck = res.data[0].subjectAndLevel === null ? [] : res.data[0].subjectAndLevel.value
        const jsonData = JSON.parse(datacheck)
        console.log(jsonData,"Json");
        const mainarr = jsonData.subjectandlevel.map((row) =>({
       code: row.subjectcode,
       updated: row.updatedBy,
       time: row.updatedOn,
      }));
      setPoint(mainarr);
      setPointFlag(true);
      setManualSelection([])
        // setPoint(jsonData.subjectandlevel.map((row) => row.subjectcode))
    })
       .catch((err)  =>
    {
       setPoint([]);
       setManualSelection([])
        console.log(err);
    })
   
  };
  getCheckpointStatus();
},[state.appr,state.eoappr]);

const getIneligibleDetails = () => {
  return muleApiInstance({
    url: "adminDateForCentres",
    
      params:{
        name:'ineligible'
      }
    
  });
};

const catchApiError = (err) => {
  console.log(err);
  navigate("/error", {
    state: { code: APP_ERROR_CODES.apiFailed },
  });
};
const getAcademicYear = (date) =>  {
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();
  return currentMonth >= 8 ? date.getFullYear() + 1 : date.getFullYear()
}

const today = new Date(); 
const academicYear = `Summer ${getAcademicYear(today)}`;

console.log(point,"getcall")
  useEffect(() =>{
  const getCheckSubjectQual = () => {
    return muleApiInstance({
      url: "subjectLevelAndQualification",
      params: {
        centreId: state.selectedOrg.orgId,
        certificationStatus: currentYr,
      },
    })
    .then((res) =>
    {
        console.log(res.data.data,"Checkpoint");
        setTab(res.data.data)
        setSubject(res.data.data)
        // setCheckPointStatus(res.data[0].checkPointStatus)
    })
       .catch((err)  =>
    {
        console.log(err);
    })
  };
  getCheckSubjectQual();
},[state.appr,state.eoappr]);
console.log(subject.length,"subjects")

const getIneligibleData = () => {
  return muleApiInstance({
    url: "candidatesSummary",
    params: {
      centreId: state.selectedOrg.orgId,
      qualificationResult: academicYear ,
      resultFlag: "Not Eligible"
    },
  });
};

const fetchIneligibleData = () =>  getIneligibleData(
)
  .then((resp) => {
    setIneligibleData(resp.data.filter((row) => row.eligibilityoverride === 'false'));
  })
  .catch((err) => {
    catchApiError(err)
  });

  useEffect(()=> {
    getIneligibleDetails().then((resp) => {
      // if(resp.data.length > 0){
      setIneligibleDetails( {
        ineligibleStartDate: resp?.data[0]?.startDate,
        ineligibleEndDate: resp?.data[0]?.endDate,
      }
    )
  })
    .catch((err) => {
      catchApiError(err);
    })
  
    fetchIneligibleData();
  },[state.ineligibleCount])

const getCheckCentre = () => {
  console.log("statuspending...")
  return muleApiInstance({
    url: "checkPointStatusForCentres",
    params: {
      centreId: state.selectedOrg.orgId,
    },
  })
//  .then((res) =>
// {
//   setCheckPointStatus(res.data[0].checkPointStatus);
// })
};
console.log(checkPointStatus,"statusPoint")
const DateConv = () =>
{
const months = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];
const currentDate = new Date();
const options = {timeZone: 'Europe/London'}
const uktime = new Date(currentDate.toLocaleString('en-US',options));
const yr = new Date(uktime).getFullYear();
const day = new Date(uktime).getDate();
const month = new Date(uktime).getMonth();
const hours = new Date(uktime).getHours();
const minutes = new Date(uktime).getMinutes();
const seconds = new Date(uktime).getSeconds();
return[`${months[month]} ${day}, ${yr} ${hours}:${minutes}:${seconds}`]
}

  console.log(point,"getSubmission")
  console.log(initialData,"datacode")
// console.log(point.subjectandlevel.subjectandlevel,"parsed")

 const tableDataWithId = tab.map((entry,index) => ({...entry,id: index}));
 console.log(tab,"state Data")

 useEffect(()=>{
if(state.appr === true)
{
  setOpen(true);
}
else{
  setOpen(false);
}
 },[state.appr])

 useEffect(()=>{
  if(state.eoappr === true)
  {
    setOpen(true);
  }
  else{
    setOpen(false);
  }
   },[state.eoappr])

 console.log(selectedCheck,"rows");
 console.log(selectedRows,"Row data")
  const handleClose = () =>
{
  setErr('');
  setOpen(false);
  dispatch(setApprove(false));
  dispatch(setEoApprove(false));
}
useEffect(() =>
{
  const newData = selectedCheck.map((Subcode) =>
  {
    const desc =  tableDataWithId.filter((row) => row.subjectlevelcode === Subcode)
    const matchingPoint = point.find((item) => item.code === Subcode);
    const overrideItem = manualSelection.includes(Subcode)
    console.log(desc,"boolean")
    return {
      subjectcode: Subcode,
      subject:  desc.length > 0 ? desc[0].subjectlevel : '',
      updatedBy: matchingPoint && overrideItem === false  ? matchingPoint.updated : state.userInfo.email,
      updatedOn: matchingPoint && overrideItem === false ? matchingPoint.time : DateConv()
    };
  });
  setCheckedData(newData);
  
},[selectedCheck,point])

const renderStatus = (selected,subjects) =>
{
 let subjcount  =  subjects.length;
 const allSubjectCodes = subjects.map((item) =>  item.subjectlevelcode);
 let allselected = allSubjectCodes.every(r => selected.includes(r));

  if(subjcount === 0 || allselected)
  {
    return "Ready to submit"
  }
  else if(subjcount > 0 && selected.length >0 && !allselected)
  {
    return "In progress"
  }
  else{
    return "Not started"
  }
}
const findStatus = (selected) =>
{
  const status = renderStatus(selected,tab);
  
  if(status === "Ready to submit")
  {
    return "A"
  }
  else if(status === "In progress")
  {
    return "P"
  }  else{
    return "I"
  }

}
console.log(checkedData,"Final Data");
const handleSplChar = (char) =>
  {
   let regex = /'/
   if(regex.test(char))
   {
     console.log("splchar")
     char = char.replace("'","")
     console.log(char,"trimmed")
     return char
   }
   else{
     return char
   }
  }



const handleSubmit = () =>
{ 
  getCheckCentre()
  .then((res) =>
  {
   if(res.data[0].checkPointStatus !== 'C')
   {
    const filteredData = tab.filter((item) => selectedCheck.includes(item.subjectlevelcode))
    console.log(filteredData,"rowdata");
    const CheckStatus =  findStatus(selectedCheck)
    const selectedSubjectCode =  checkedData.map((row) => ({
      subjectcode: row.subjectcode,
      description: row.subject,
      updatedBy: row.updatedBy,
      updatedOn: row.updatedOn,
     }))
     const selectedSubject = {
      subjectandlevel : selectedSubjectCode
     }
    mulePostApiInstance({
      url: "checkPointStatusForCentres",
      data: [
         {
          centreId: state.selectedOrg.orgId,
          modifiedBy: handleSplChar(state.userInfo.email),
          checkPointStatus: CheckStatus,
          subjectAndLevel: selectedSubject,
        }
       ],
    })
      .then((_resp) => {
        console.log(_resp)
      })
      .catch((_err) => {
       console.log(_err)
      });
    
      setOpen(false);
      dispatch(setEoApprove(false));
      dispatch(setApprove(false));
   }
   else{ 
    setTimeout(()=>
  {
    setErr("Checkpoint Declaration was already completed!")
  },3000);
  console.log("AlreadySubmitted");
  }
  })
}
const handleEoSubmit = () =>
{
  const filteredData = tab.filter((item) => selectedCheck.includes(item.subjectlevelcode))
  console.log(filteredData,"rowdata");
  const CheckStatus =  'C';
  const selectedSubjectCode =  checkedData.map((row) => ({
    subjectcode: row.subjectcode,
    description: row.subject,
    updatedBy: row.updatedBy,
    updatedOn: row.updatedOn,
   }))
   const selectedSubject = {
    subjectandlevel : selectedSubjectCode
   }
  mulePostApiInstance({
    url: "checkPointStatusForCentres",
    data: [
       {
        centreId: state.selectedOrg.orgId,
        modifiedBy: handleSplChar(state.userInfo.email),
        checkPointStatus: CheckStatus,
        subjectAndLevel: selectedSubject,
      }
     ],
  })
    .then((_resp) => {
      console.log(_resp)
    })
    .catch((_err) => {
     console.log(_err)
    });
    setEoFlag(false)
    setOpen(false);
    dispatch(setEoApprove(false));
  }

const handleCheckBox = (rowId,Subjectcode,SubjectLevel) =>
{
  const isManuallyChecked = manualSelection.includes(Subjectcode);
  const isChecked = selectedCheck.includes(Subjectcode)
;

if(isChecked)
{
  setSelectedCheck((prev) => prev.filter((val) => val !== Subjectcode))
  setManualSelection((prev) => prev.filter((val) => val !== Subjectcode))
}
else{
  setSelectedCheck((prev) => [...prev,Subjectcode])
  if(!isManuallyChecked)
  {
  setPointFlag(false);
  setUser(state.userInfo.email)
  setTstamp(DateConv())
  setManualSelection((val) => [...val,Subjectcode])
  }
}
setOverrideData((prev) =>
{
  const updatedData = {...prev};
  delete updatedData[Subjectcode];
  return updatedData;
})
};
console.log(overrideData,"Main data")
console.log(manualSelection,"??")
console.log(selectedCheck,"datacheck");
const handleSelectAll = () =>
{
  if(selectedCheck.length === tab.length)
  {
    setSelectedCheck([]);
    setManualSelection([])
  }
  else{
    const allSubjectCodes = tab.map((item) =>  item.subjectlevelcode);
    setSelectedCheck(allSubjectCodes)
    setManualSelection(allSubjectCodes)
    setUser(state.userInfo.email)
    setTstamp(DateConv())
  }
}
//inside this point state we are having subjectcodes only [10,28]
useEffect(() =>
{
  setSelectedCheck(point.map((row) => row.code))
},[point])

console.log("selectedValue",selectedCheck)
// useEffect(() =>
// {
//   <EoCheckpointModal />
// },[Eoflag])

  return (
    <div component={Paper} className="welcome-message-wrapper" data-testid="welcomewrapper" style ={{position: 'relative'}}>
      <div className="notification-wrap mb-5 flex-column">
        <div ref={notificationRef}>
        {state.appr ?
  <Dialog open ={open} onClose = {handleClose} maxWidth = "md" 
  PaperProps = {{style:{position: 'absolute',top:'50%',left:'50%',width:'70%',transform:'translate(-50%,-50%)'}}}>
  <Box>
  <Typography id="modal-modal-title" variant="h6" component="h2">
  <div style={{display:'flex',alignItems:'center'}}>  
  <center><span style ={{paddingLeft:'10px'}}><b>Checkpoint Status</b></span></center>
  <Link style={{color: 'black'}}> <CloseIcon style={{marginLeft : selectedCheck.length > 0 ? '775px' : '775px'}} onClick = {() => {handleClose()}}/></Link>
   </div>
   <div ref={notificationTeacherRef}>
   {console.log(subject.length,"length")}
   <Notification
            size="small"
            style={{ margin: 8 }}
          >
          {subject.length > 0 ?
          <div className = "title">
         <b>Term-time checkpoint status: Estimated completion date declaration</b>
         <br /><br />
            <div className="notification-text">
            By checking the box you are confirming that the data for the subject and level has been checked and all candidates completing their course this year have an estimated completion date of Summer {currentyr}
            <br /><br />
           <div> Note: Submitting the declaration to OCR requires the exams officer role.</div>
           </div>
          </div> : <div><b>Term-time checkpoint status: No candidates with an estimated completion date of Summer {currentyr}</b>
<br /><br />
By submitting this declaration, you are confirming no candidates are expecting to complete a qualification for any subjects this summer.
<br /><br />
If candidates are expecting a qualification result this summer, the estimated completion date must be amended to Summer {currentyr}.
<br /><br />
Note: Submitting the declaration to OCR and amending the estimated completion date requires the exams officer role.</div> }
   </Notification>
        </div>
  </Typography>
  <TableContainer component={Paper}>
  <Table  aria-label="simple table">
  <TableHead color="primary">
    <TableRow>
      <TableCell>Subject</TableCell>
      <TableCell>
        <div className = {classes.checkboxCell}>
        <span> Estimated completion date checked</span> 
      </div>
      </TableCell>
      <TableCell >Checked By</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
  {tab.map((row,idx) => (
      <TableRow
      >
        <TableCell>{row.subjectlevel}</TableCell>
        <TableCell><Checkbox style ={{width:'40px',marginLeft:'70px'}}
        classes={{
            root: classes.checkbox,
          }} checked = {selectedCheck.includes(row.subjectlevelcode)} 
        onChange = {() => handleCheckBox(idx,row.subjectlevelcode,row.subjectlevel)}/></TableCell>
        <TableCell style={{width: '400px'}}>
        {manualSelection.includes(row.subjectlevelcode) ?
        `${user} on ${tstamp}` : selectedCheck.includes(row.subjectlevelcode) ? 
          `${point.find((item) => item.code === row.subjectlevelcode)?.updated} on ${point.find((item) => item.code === row.subjectlevelcode)?.time}` : '' }</TableCell>
      </TableRow>
    ))} 
 </TableBody>
    </Table>
    {err ? <Stack sx={{ width: '100%' }} spacing={2}>
    <Alert severity="error">{err}</Alert></Stack> : ''}
    <center style = {{padding: '10px'}}>Status :  <span style ={{color: theme.palette.primary.main}}><b>{renderStatus(selectedCheck,subject)} </b></span> </center>
    <center><Button onClick ={() => handleSubmit()}>Save</Button></center>
    </TableContainer>
    </Box>
    </Dialog>
    : ''}

    {state.eoappr ?
  <Dialog open ={open} onClose = {handleClose} maxWidth = "md" 
  PaperProps = {{style:{position: 'absolute',top:'50%',left:'50%',width:'70%',transform:'translate(-50%,-50%)'}}}>
  <Box>
  <Typography id="modal-modal-title" variant="h6" component="h2">
  <div style={{display:'flex',alignItems:'center'}}>  
  <center><span style ={{paddingLeft:'10px'}}><b>Checkpoint Status</b></span></center>
  <Link style={{color: 'black'}}> <CloseIcon style={{marginLeft : selectedCheck.length > 0 ? '775px' : '775px'}} onClick = {() => {handleClose()}}/></Link>
   </div>
   <div ref={notificationEORef}>
   <Notification
            size="small"
            style={{ margin: 8 }}
          >
          {subject.length > 0 ?
          <div className = "title">
            <b>Term-time checkpoint status: Estimated completion date declaration</b>
            <br /><br />
            <div className="notification-text">
            By checking the box you are confirming that the data for the subject and level has been checked and all candidates completing their course this year have an estimated completion date of Summer {currentyr}
            <br /><br />
           <div> Note: Submitting the declaration to OCR requires the exams officer role.</div>
           </div>
          </div> : <div><b>Term-time checkpoint status: No candidates with an estimated completion date of Summer {currentyr}</b>
<br /><br />
By submitting this declaration, you are confirming no candidates are expecting to complete a qualification for any subjects this summer.
<br /><br />
If candidates are expecting a qualification result this summer, the estimated completion date must be amended to Summer {currentyr}.
<br /><br />
Note: Submitting the declaration to OCR and amending the estimated completion date requires the exams officer role.</div>}
          </Notification>
          </div>
  </Typography>
  <TableContainer component={Paper}>
  <Table  aria-label="simple table">
  <TableHead color="primary">
    <TableRow>
      <TableCell>Subject</TableCell>
      <TableCell>
        <div className = {classes.checkboxCell}>
        <span> Estimated completion date checked</span>
      </div>
      </TableCell>
      <TableCell>Checked By</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
  {tab.map((row,idx) => (
      <TableRow
      >
        <TableCell>{row.subjectlevel}</TableCell>
        <TableCell><Checkbox style ={{width:'40px',marginLeft:'70px'}}
        classes={{
          root: classes.checkbox,
        }}
        checked = {selectedCheck.includes(row.subjectlevelcode)} 
        onChange = {() => handleCheckBox(idx,row.subjectlevelcode,row.subjectlevel)}/></TableCell>
        <TableCell>
        {manualSelection.includes(row.subjectlevelcode) ?
        `${user} on ${tstamp}` : selectedCheck.includes(row.subjectlevelcode) ? 
          `${point.find((item) => item.code === row.subjectlevelcode)?.updated} on ${point.find((item) => item.code === row.subjectlevelcode)?.time}` : '' }</TableCell>
      </TableRow>
    ))} 
 </TableBody>
    </Table>
    <center style = {{padding: '10px'}}>Status : <span style ={{color: theme.palette.primary.main}}><b>{renderStatus(selectedCheck,subject)}</b></span> </center>
    <div style={{display:'flex',justifyContent: 'center',gap:'35px'}}>
      <Button  onClick ={() => handleSubmit()}>Save</Button>
      <Button  disabled = {renderStatus(selectedCheck,subject) === "Ready to submit" ? false : true} onClick ={() => handleEoSubmit()}>Submit</Button>
    </div>
    </TableContainer>
    </Box>
    </Dialog>
    : ''}

          <Notification
            closeable={true}
            size="small"
            style={{ margin: 8 }}
            title="Last updated"
            onClose={() => {
              notificationRef.current.className = "d-none";
            }}
          >
            <div className="notification-text">
              Candidate progress information was last updated on{" "}
              {format(
                dayjs.utc(welcomePageProps.lastupdatedtime).tz("Europe/London")
                  .$d,
                "dd MMM yyyy, HH:mm"
              )}{" "}
              and the next update will be on{" "}
              {format(
                dayjs.utc(welcomePageProps.nextupdatedtime).tz("Europe/London")
                  .$d,
                "dd MMM yyyy, HH:mm"
              )}
            </div>
          </Notification>
        </div>
        {state.selectedOrg.role === "INTERNAL_ADMIN" && dataCheck?.length !== 0 ? 
          <IaCheckpointNotification />
         : 
          ''
        }
        {state.selectedOrg.role === "EXAMS_OFFICER" && dataCheck?.length !== 0 ? 
        
         Eoflag  ? <EoCheckpointModal /> : <Notification
          closeable={true}
          size="small"
          title=""
          onClose={() => {
            notificationRef.current.className = "d-none";
          }}
        >
          <div className="notification-text">
          Estimated completion date declaration has been submitted by {state.userInfo.email} on {DateConv()}
         </div>
      </Notification>   :  ''
        }
        {state.selectedOrg.role === "TEACHER" && dataCheck?.length !== 0 ? 
          
       <TeacherCheckPoint/> : (
          ''
        )}
        {isValidCheckpointDate(ineligibleDetails.ineligibleStartDate, ineligibleDetails.ineligibleEndDate) && ineligibleData.length > 0 ? (
          <div ref={alertRef}>
        <Notification
            closeable={true}
            size="small"
            style={{margin:'8px',color: '#BD4F00', backgroundColor:'#FCF8F3', border:'1px solid #BD4F00'}}
            onClose={() => {
              alertRef.current.className = "d-none";
            }}
            variant="warning"
          >
          <div className="title" style={{textAlign:'left'}}>
          <b>Warning: Candidates at risk of not receiving a qualification result</b>
            <div className="notification-text" style={{marginTop:6}}>
              There are <b>{ineligibleData.length}</b> candidates with an estimated completion date of {academicYear} that are currently not eligible for a result
            </div>
            <div>
              <Button onClick={ () => {dispatch(setAlertClick(true)); dispatch(setTabValue('3')); dispatch(setSummaryViewActive(false)); dispatch(setIneligibleViewActive(true)); navigate("/dashboard/candidates"); }}
               style={{backgroundColor: "#bd4f00",
               padding: "12px 12px",marginTop:10 ,cursor:'pointer'}}>View candidates</Button>
              </div>
            </div>
          </Notification>
          </div>
        ) 
        : null }
      </div>
      <div className="col d-flex">
        <div className="m-auto mt-3">
          <h2 className="mb-5">{landingPageLabels.welcomeMessage.line_one}</h2>
          <p className="">{landingPageLabels.welcomeMessage.line_two}</p>
          <p className="">{landingPageLabels.welcomeMessage.line_three}</p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessageScreen;
